<template>
  <div class="container py-5">

    <img
      class="img-max rounded"
      src="@/assets/images/zh-TW/slide6.jpg"
    />

    <p class="fw-bold">2023 TDA臺灣皮膚科醫學會</p>
    <p>時間：2023/12/08(五)-10(日)</p>
    <p>地點：高雄展覽館</p>
    <p>展位：B38</p>

    <p>
     OBSERV®擁有10種光源模式，只需10秒時間，全面探勘淺層至深層的肌膚困擾，根據肌膚狀態，為你規劃專屬個人療程報告。
      <br><br>OBSERV 520x的三大看點
      <br>＊360度光源模式
      <br>＊面部年輕化 
      <br>＊膚色數值分析 
      <br>邀請各位蒞臨攤位 B38，了解更多OBSERV®！
    </p>



  </div>
</template>
<script>
export default {};
</script>
